import React from 'react';
import { ArrowRightIcon } from '../../utils/IconUtils';
import { useNavigate } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ category, title, name }) => {

    const navigate = useNavigate();

    const getTitle = (id) => {
        switch (id) {
            case "ev-tipi-cop-posetleri":
                return "EV TİPİ ÇÖP POŞETLERİ";
            case "profesyonel-cop-posetleri":
                return "PROFESYONEL ÇÖP POŞETLERİ";
            case "astarli-cop-posetleri":
                return "ASTARLI ÇÖP POŞETLERİ";
            case "buzgulu-cop-posetleri":
                return "BÜZGÜLÜ ÇÖP POŞETLERİ"
            case "medikal-cop-posetleri":
                return "MEDİKAL ÇÖP POŞETLERİ"
            default:
              return "EV TİPİ ÇÖP POŞETLERİ"
        }
    }

    return (
        <div className={styles.breadcrumbContainer}>
            <div className={styles.breadcrumbMainContainer}>
                <span onClick={() => navigate(`/`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>Anasayfa</span>
                <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                <span onClick={() => navigate(`/urunler`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>Urunler</span>
                <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                <span onClick={() => navigate(`/urunler/${name}`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>{getTitle(name)}</span>
                <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                <span style={{ fontSize: "14px", fontWeight: 600, color: "#f2f2f2" }}>{`${category} - ${title}`}</span>
            </div>
        </div>
    )
}

export default Breadcrumb