import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import propertyImage from '../../static/product-property.png'
import { productDatas } from '../../components/mock/MockData';
import styles from './ProductDetailPage.module.scss';
import { useParams } from 'react-router-dom';

const ProductDetailPage = () => {

    const { name, id } = useParams();
    const [activeImage, setActiveImage] = useState(0);
    const [productDetailData, setProductDetailData] = useState({});

    useEffect(() => {
        productDatas.map((value) => {
            if(value.id == name){
                value.data.map(val => {
                    if(val.no == id){
                        setProductDetailData(val);
                    }
                })
            }
        })
    },[])

    return (
        <div className={styles.productDetailPage}>
            <Breadcrumb category={productDetailData.category} title={productDetailData.title} name={name} />
            {
                Object.keys(productDetailData).length > 0 
                &&
                <div className={styles.productContainer}>
                    <div className={styles.productMainContainer}>
                        <div className={styles.leftContainer}>
                            {
                                productDetailData.img.map((val, idx) => (
                                    <div onClick={() => setActiveImage(idx)} className={activeImage === idx ? styles.activeLeftImageContainer : styles.leftImageContainer}>
                                        <img style={{ width: "100%", height: "100%" }} src={val} alt="" />
                                    </div>
                                ))
                            }
                        </div>
                        <div className={styles.mainContainer}>
                            <div className={styles.mainImageContainer}>
                                <img style={{ width: "100%", height: "100%" }} src={productDetailData.img[activeImage]} alt="" />
                            </div>
                        </div>
                        <div className={styles.rightContainer}>
                            <div className={styles.properyContainer}>
                                <h2>{productDetailData.category}</h2>
                                <h1>{productDetailData.title}</h1>
                            </div>
                            {/* <div className={styles.properyContainer}>
                                <img style={{ width: "100%", height: "100%" }} src={productBPA} alt="" />
                            </div> */}
                            <div className={styles.properyContainer}>
                                <div className={styles.properyItemCont}>
                                    <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                    <div className={styles.textContainer}>
                                        <h3>ÜRÜN</h3>
                                        <h4>{productDetailData.product}</h4>
                                    </div>
                                </div>
                                <div className={styles.properyItemCont}>
                                    <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                    <div className={styles.textContainer}>
                                        <h3>RULO</h3>
                                        <h4>{productDetailData.roll}</h4>
                                    </div>
                                </div>
                                <div className={styles.properyItemCont}>
                                    <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                    <div className={styles.textContainer}>
                                        <h3>KOLİ</h3>
                                        <h4>{productDetailData.parcel}</h4>
                                    </div>
                                </div>
                                <div className={styles.properyItemCont}>
                                    <img style={{ width: "40px", height: "40px" }} src={propertyImage} alt="" />
                                    <div className={styles.textContainer}>
                                        <h3>PALET</h3>
                                        <h4>{productDetailData.palette}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProductDetailPage