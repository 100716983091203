import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductBoxWithDetail from '../../components/productBoxWithDetail/ProductBoxWithDetail';
import { ArrowRightIcon } from '../../utils/IconUtils';
import { productDatas } from '../../components/mock/MockData';

import styles from './ProductPage.module.scss';

const ProductPage = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const getTitle = (id) => {
        switch (id) {
            case "ev-tipi-cop-posetleri":
                return "EV TİPİ ÇÖP POŞETLERİ";
            case "profesyonel-cop-posetleri":
                return "PROFESYONEL ÇÖP POŞETLERİ";
            case "astarli-cop-posetleri":
                return "ASTARLI ÇÖP POŞETLERİ";
            case "buzgulu-cop-posetleri":
                return "BÜZGÜLÜ ÇÖP POŞETLERİ"
            case "medikal-cop-posetleri":
                return "MEDİKAL ÇÖP POŞETLERİ"
            default:
              return "EV TİPİ ÇÖP POŞETLERİ"
        }
    }

    return (
        <div className={styles.productPageContainer}>
            <div className={styles.breadcrumbContainer}>
                <div className={styles.breadcrumbMainContainer}>
                    <span onClick={() => navigate(`/`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }}>Anasayfa</span>
                    <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                    <span onClick={() => navigate(`/urunler`)} style={{ fontSize: "14px", fontWeight: 400, color: "#f2f2f2", cursor: "pointer" }} >Urunler</span>
                    <ArrowRightIcon style={{ margin: "0px 6px" }} color={"#fff"} />
                    <span style={{ fontSize: "14px", fontWeight: 600, color: "#f2f2f2" }}>{getTitle(id)}</span>
                </div>
            </div>
            <div className={styles.pageTitleContainer}>
                <h1>{getTitle(id)}</h1>
            </div>
            <div className={styles.productContainer}>
                <div className={styles.productMainContainer}>
                    {
                        productDatas.map(value => {
                            if(value.id === id){
                                return(
                                    value.data.map(val => (
                                        <ProductBoxWithDetail key={val.no} name={value.id} data={val} title={val.title} image={val.img[0]} />
                                    ))
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductPage;