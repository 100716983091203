import React from 'react';
import Slider from 'react-slick';
import featureIcon1 from '../../static/feature-box-icon.png';
import featureIcon2 from '../../static/co2.png';
import featureIcon3 from '../../static/surdurulebilir.png';
import featureIcon4 from '../../static/teknoloji.png';
import FeatureBox from '../../components/featureBox/FeatureBox';
import { ArrowRightIcon, PlayIcon } from '../../utils/IconUtils';
import ProductGroupBox from '../../components/productGroupBox/ProductGroupBox';
import StatisticsSection from '../../components/statisticsSection/StatisticsSection';
import { useNavigate } from 'react-router-dom';
import productGroupImage1 from '../../static/ev-tipi.jpg';
import productGroupImage2 from '../../static/profesyonel.jpg';
import productGroupImage3 from '../../static/astarli2.jpg';
import productGroupImage4 from '../../static/buzgulu.jpg';
import productGroupImage5 from '../../static/medikal.jpg';
import slider1 from '../../static/bg1.jpeg';
import slider2 from '../../static/bg2.jpg';

import styles from './HomePage.module.scss';

const HomePage = () => {

    const navigate = useNavigate();

    const sliderItems = [
        {
            id: 1,
            url: slider1
        },
        {
            id: 2,
            url: slider2
        },
    ]

    const featureBoxData = [
        {
            id: 0,
            title: "SORUMLU ÇEVRE",
            content: "Hammadddelerin Ekonomik Döngüye Kazandırılması",
            img: featureIcon1
        },
        {
            id: 1,
            title: "DAHA AZ ATIK",
            content: "Üretim Yöntemlerimiz ile Daha Az Plastik Kullanımı Sağlanır",
            img: featureIcon2
        },
        {
            id: 2,
            title: "SÜRDÜRÜLEBİLİR ÜRÜN",
            content: "Sürdürülebilir Ürünler Sayesinde Sağlıklı Çevre Faktörü",
            img: featureIcon3
        },
        {
            id: 3,
            title: "YÜKSEK TEKNOLOJİ",
            content: "Endüstri 4.0 Takipçisi Son Teknoloji Tesislerimiz Mevcuttur",
            img: featureIcon4
        },
    ]

    const productGroupData = [
        {
            id: 0,
            title: "EV TİPİ ÇÖP POŞETLERİ",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat iste voluptates numquam quibusdam sit quae in officia incidunt maiores, nulla autem. Lorem ipsum dolor sit quae in officia incidunt maiores, nulla autem quae in officia incidunt maiores, nulla autem.",
            bgColor: "#909c0b",
            hoverColor: "#c1d100",
            img: productGroupImage1,
            url: '/urunler/ev-tipi-cop-posetleri'
        },
        {
            id: 1,
            title: "PROFESYONEL ÇÖP POŞETLERİ",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat iste voluptates numquam quibusdam sit quae in officia incidunt maiores, nulla autem. Lorem ipsum dolor sit quae in officia incidunt maiores, nulla autem.",
            bgColor: "#244c5a",
            hoverColor: "#377F99",
            img: productGroupImage2,
            url: '/urunler/profesyonel-cop-posetleri'
        },
        {
            id: 2,
            title: "ASTARLI ÇÖP POŞETLERİ",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat iste voluptates numquam quibusdam sit quae in officia incidunt maiores, nulla autem. Lorem ipsum.",
            bgColor: "#909c0b",
            hoverColor: "#c1d100",
            img: productGroupImage3,
            url: '/urunler/astarli-cop-posetleri'
        },
        {
            id: 3,
            title: "BÜZGÜLÜ ÇÖP POŞETLERİ",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat iste voluptates numquam quibusdam sit quae in officia incidunt maiores, nulla autem.",
            bgColor: "#244c5a",
            hoverColor: "#377F99",
            img: productGroupImage4,
            url: '/urunler/buzgulu-cop-posetleri'
        },
        {
            id: 4,
            title: "MEDİKAL ÇÖP POŞETLERİ",
            content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos fugit saepe adipisci illo non aspernatur optio veniam fugiat",
            bgColor: "#909c0b",
            hoverColor: "#377F99",
            img: productGroupImage5,
            url: '/urunler/medikal-cop-posetleri'
        },
    ]

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        arrows: false,
    };

    return (
        <div className={styles.homePageContainer}>
            <div className={styles.sliderContainer}>
                <Slider {...settings}>
                    {
                        sliderItems.map((val) => (
                            <div key={val.id} className={styles.imageContainer}>
                                <img src={val.url} alt="" />
                            </div>
                        ))
                    }
                </Slider>
            </div>
            <div className={styles.featureContainer}>
                <div className={styles.featureMainContainer}>
                    <div className={styles.featureTitleContainer}>
                        <span><strong>Geleceğe Düşünüyor,</strong> Doğayı Koruyoruz...</span>
                    </div>
                    <div className={styles.boxesContainer}>
                        {
                            featureBoxData.map((val) => (
                                <FeatureBox id={val.id} title={val.title} content={val.content} image={val.img} />
                            ))
                        }
                        <div className={styles.videoBoxContainer}>
                            <div className={styles.imageContainer}>
                                <PlayIcon />
                            </div>
                            <div className={styles.textContainer}>
                                <span>TANITIM FİLMİNİ</span>
                                <span>İZLEMEK İÇİN</span>
                                <span>TIKLAYIN</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.aboutContainer}>
                <div className={styles.leftContainer}>
                    <img style={{ width: "100%", height: "100%" }} src="https://picsum.photos/950/552" alt="" />
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.mainContainer}>
                        <h2>Hakkımızda</h2>
                        <p>
                            Zerpak Ambalaj 2011 yılında İstanbul Kıraç'da endüstriyel atık çöp torbası ihtiyacını karşılamak amacı ile kurulmuştur. Yıllar içinde yapmış olduğu marka ve makine yatırımları ile bugün ağırlıklı olarak çöp torbası, mop ve koli bandı üretiminde faaliyet göstermektedir. Zerpak Ambalaj olarak çevre sorumluluğu bilinci birinci önceliğimizdir. Bu bağlamda şirketimiz; güvenilirlik, esneklik, yenilikçilik ve sürdürülebilirlik gibi temel değerleri ile müşterilerine özel çözümler sunmaktadır.
                        </p>
                        <p>
                            İstanbul Kıraç’ta, 1600 metrekarelik kapalı alanda 2011 yılında kurulan Zerpak Ambalaj bugün Hadımköy fabrikada, 5500 metrekare kapalı alanda 100'ü aşkın personeli ile ayda 450 tonun üzerinde üretim yapmakta olup, ürünlerinin yurtiçi ve yurtdışındaki tüketicileri ile buluşmasını sağlamaktadır.
                        </p>
                        <button onClick={() => navigate('/kurumsal')}>
                            <span>Devamını Oku</span>
                            <ArrowRightIcon color={"#c1d100"} />
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.productGroupsContainer}>
                <div className={styles.productGroupsMainContainer}>
                    <div className={styles.productGroupsTitleContainer}>
                        <span><strong>Ürün</strong> Gruplarımız</span>
                    </div>
                    <div className={styles.productGroupBoxes}>
                        {
                            productGroupData.map((val) => (
                                <ProductGroupBox 
                                    key={val.id}
                                    title={val.title} 
                                    content={val.content} 
                                    bgColor={val.bgColor} 
                                    hoverColor={val.hoverColor} 
                                    image={val.img}
                                    url={val.url}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* <div className={styles.qualityContainer}>
                <div className={styles.qualityMainContainer}>
                    <div className={styles.leftContainer}>
                        <h1>Kalite Belgelerimiz</h1>
                        <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi debitis illo quasi qui mollitia earum officia velit voluptatum unde, veniam tempore rem soluta iusto nulla, sequi nobis, quia recusandae accusantium.</span>
                        <ul>
                            <li>Hafif</li>
                            <li>Verimi Yüksek</li>
                            <li>Ürünü Korur</li>
                            <li>Gıdaya Uygun</li>
                        </ul>
                        <div className={styles.buttonContainer}>
                            <button>
                                <span>Sertifikalarımız</span>
                                <ArrowRightIcon color={"#c1d100"} />
                            </button>
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.imageContainer}>
                            <img src="https://picsum.photos/465/465" alt="" />
                        </div>
                    </div>
                </div>
            </div> */}
            <StatisticsSection />
        </div>
    )
}

export default HomePage